<template>
  <div class="container content">
    <div class="headline">
      <h1>&nbsp;</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education.enroll.thank-you.header') }}</p>
          <div class="columns">
              {{ $t('message.education.enroll.thank-you.txt', { education: education.name, start: this.$moment(this.education.course_start).format('YYYY-MM-DD') }) }}
          </div>
        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    computed: {
        user() {
            return this.$store.getters['getUser']
        }, 
        education() {
          var __id = parseInt(this.$route.params.education_id, 10)
          var educations = this.$store.getters['getCourses']
          var education = educations.find(ed => ed.id == __id)
          return education
        }
    },
    mounted() {
       this.$store.dispatch("fetchEducations");
    }
}
</script>